import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey, faTrash} from "@fortawesome/free-solid-svg-icons";
import Datatable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";
import {Modal, Button, Container, Row, Col, Alert, Form, Spinner} from 'react-bootstrap';
import AddUser from "./components/AddUser";
import services from "../services/Services";
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from "../utils/Styles"
import AlertIcintracom from "./components/AlertIcintracom";

const CustomLoader = () => (
  <div style={{ padding: '24px' }}>
      <Spinner animation="border" variant="primary"/>
      &nbsp;<b><label>Cargando...</label></b>
  </div>
);

class AdminUsers extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            dataUsers: [],
            nameUser: '',
            idUser: '',
            idCliente: '',
            openModal: false,
            show: false,
            showAlertUpdateMail: false,
            showAlertUpdateRol: false,
            showUpdateActivate: false,
            showAlertDeleteUser: false,
            showAlertAddUser: false,
            loading:true,
            dataRoles: []
        }
        this.getUsers = this.getUsers.bind(this)
    }

    componentDidMount() {
        this.getRoles();
        setTimeout(() => {
            this.getUsers();
        }, 100);
    }

    /**
     * obtiene los usuario y despues obtiene el rol asignado a cada usuario
     */
    getUsers() {
        services.getUsersWithRolAssignedMysql().then(response => {
            this.proccesDataUsers(response.data)
        })
    }

    getRoles() {
        services.getRolesClient().then(response => {
            this.setState({dataRoles: response.data})
        }).catch(error => console.log("ICIntracom:", error.message))
    }

    proccesDataUsers(data) {
        const infoUsers = data
        let dataFinTemp = []
        /**
         * crea los usuarios unicos
         */
        let dataUsers = infoUsers.reduce((users, line, index) => {
            users[line.FIRST_NAME] = users[line.FIRST_NAME] || []
            users[line.FIRST_NAME].push(line)
            return users
        }, {})

        //       console.log("dataUsers:::", dataUsers)

        /**
         * agrupa valores que regresaron de BD, para asginarlos a un array,
         * para asignarlos al mapeo de la tabla
         *  primero itera por los valores unicos que resultaron de la función reduce
         */
        for (let key in dataUsers) {

            let itemTemp = {
                "USERNAME": dataUsers[key][0].USERNAME,
                "ID_USUARIO": dataUsers[key][0].ID_USUARIO,
                "ENABLED": dataUsers[key][0].ENABLED,
                "ROL": dataUsers[key][0].ROL,
                "EMAIL": dataUsers[key][0].EMAIL,
                "REALM_ID": dataUsers[key][0].REALM_ID,
                "FIRST_NAME": dataUsers[key][0].FIRST_NAME,
                "FECHA_CREACION": dataUsers[key][0].FECHA_CREACION,
                "TIPOUSUARIO": "",
                "IDCLIENTE": "",
                "IDMYSQL": ""
            }
            for (let keyInterno in dataUsers[key]) {
                let obj = dataUsers[key][keyInterno]
                if (obj.ATRIBUTO === 'IDCLIENTE') itemTemp.IDCLIENTE = obj.VALOR
                if (obj.ATRIBUTO === 'TIPOUSUARIO') itemTemp.TIPOUSUARIO = obj.VALOR
                if (obj.ATRIBUTO === 'IDMYSQL') itemTemp.IDMYSQL = obj.VALOR
            }
            dataFinTemp.push(itemTemp)
        }

        this.setState({dataUsers: dataFinTemp, loading: false})
    }


    onUpdateActivate = (row, e) => {
        let body = {"enabled": e.target.checked}
        services.updateActivateUser(row.ID_USUARIO, body).then(response => {
            console.log("regreso actualización::::", response)
            this.handlerShowUpdateActivate()
        }).catch(error => console.log("ICIntracom error activar:", error.message))
    }

    onUpdateEmail = (row, index, e) => {
        const val = e.target.value
        const id = row.ID_USUARIO

        let newDataUsers = this.state.dataUsers.map(item => {
            if (id === item.id) {
                item.email = val
            }
            return item
        })
        this.setState({dataUsers: newDataUsers})
        const body = {"email": val}
        //console.log("bodyy::: ", body

        services.updateUser(id, body).then(response => {

            const bodyBD = {"email": val, "IdCliente": row.IDMYSQL}
            services.editUserMysql(bodyBD).then(response => {
                this.setState({showAlertUpdateMail: true})
                this.getUsers();
            })


        }).catch(error => console.log("error update email:", error.message))
    }

    onUpdateRol = (row, e) => {

        let rolName = e.target.options[e.target.selectedIndex].getAttribute('title')
        let rolID = e.target.value
        let userID = row.ID_USUARIO
        //console.log("ROW::",row)
        let body = [{
            "id": rolID,
            "name": rolName,
            "containerId": "2758d422-8dff-4b47-9706-40559e829d47"
        }]
        //console.log("body::",body)
        //
        //obtiene  los roles que hayan sido asignados anteriormente

        services.getAllRolesAssigned(userID).then(responseRolesAssigned => {
            const newRol = responseRolesAssigned.data
            //console.log("responseRolesAssigned:,",newRol )
            services.deleteRolAssigned(userID, newRol).then(rDelete => {
                //console.log("rDelete:::::",rDelete)
                services.setRoleUser(userID, body).then(response => {
                    this.setState({showAlertUpdateRol: true})
                }).catch(error => console.log("ICIntracom - updateRol", error.message))

            }).catch(error => console.log("ICIntracom - deleteRolAssigned", error.message))

        }).catch(error => console.log("ICIntracom - getAllRolesAssigned", error.message))


    }


    onResetPass = (row) => {
        const body = {"type": "password", "value": "icintracom", "temporary": true}
        services.resetPassword(row.ID_USUARIO, body).then(response => {
            if (response.status === 204) {
                setTimeout(() => this.setState({show: true}), 100)
            }
            this.setState({
                openModal: false,
                show: false,
                showAlertUpdateMail: false,
                idUser: "",
                nameUser: ""
            })
        }).catch(error => console.log("ICIntracom", error.message))
    }

    onDeleteUser = () => {

        services.deleteUser(this.state.idUser).then(() => {

            let body = { IdCliente: String(this.state.idCliente)}

            services.deleteUserMysql(body).then(() => {
                this.setState({
                    openModal: false,
                    show: false,
                    idUser: "",
                    idCliente: "",
                    nameUser: ""
                })

                this.handlerShowAlertDeleteUser()
                this.getUsers();
            }).catch(error => console.log("ICIntracom error - borrar usuario", error.message))

        }).catch(error => console.log(error.message))
    }

    columns = [
        {name: "ID Cliente", selector: row => row.IDCLIENTE, sortable: true,  export: false},
        {name: "Usuario", selector: row => row.USERNAME, sortable: true, export: false},
        {name: "Nombre", selector: row => row.FIRST_NAME, sortable: true, export: false},
        {
            name: "Email", selector: row => row.EMAIL, sortable: true,  export: false, wrap: true,
            cell: (row, index) => {
                return <Form.Control
                  type="email"
                  style={styles.input}
                  key={row.IDCLIENTE}
                  defaultValue={row.EMAIL}
                  onBlur={(e) => {
                      this.onUpdateEmail(row, index, e)
                  }}
                />
            }
        },
        {name: "Fecha Creación", selector: row => row.FECHA_CREACION, sortable: true,  export: false},
        {
            name: "Rol", selector: row => row.ROL, button: true, width: '150px', export: false,
            cell: (row, index) => {
                const rol = this.state.dataUsers.filter(item => item.USERNAME === row.USERNAME ? item : '')
                return <select name="select" style={{width:'100px'}} onChange={(e) => this.onUpdateRol(row, e)}>

                    {this.state.dataRoles.map((e, key) => {
                        return <option key={key} title={e.name}
                                       value={e.id}
                                       selected={rol[0].ROL === e.name ? 'selected' : ''}>
                            {e.name}
                        </option>;
                    })}

                </select>
            }
        },
        {
            name: "Activado", selector: row => row.ENABLED, sortable: true, width: '100px', export: false,
            cell: (row) => (
              <Form.Check type="switch" defaultChecked={row.ENABLED}
                          onChange={(e) => {
                              this.onUpdateActivate(row, e)
                          }}/>
            )
        },
        {
            name: "Reset Pass.", button: true,  export: false,
            ignoreRowClick: false,
            allowOverflow: true,
            cell: (row) => <FontAwesomeIcon icon={faKey} className="hand" onClick={() => this.onResetPass(row)}/>
        },
        {
            name: "Eliminar", button: true, export: false,
            ignoreRowClick: false,
            allowOverflow: true,
            cell: (row) => {
                if (row.USERNAME === 'admin') {
                    return ""
                } else {
                    return <FontAwesomeIcon icon={faTrash} className="hand" onClick={() => {
                        this.setState({
                            openModal: true,
                            idCliente: row.IDMYSQL,
                            idUser: row.ID_USUARIO,
                            nameUser: row.USERNAME
                        })
                    }}/>
                }
            }
        },
        {name: "Tipo Usuario", selector: row => row.TIPOUSUARIO, sortable: true, export: false},
    ]


    onCloseModal = () => this.setState({openModal: false})

    handlerOpenAlertAdd = () => this.setState({showAlertAddUser: true})
    handlerCloseAlertAdd = () => this.setState({showAlertAddUser: false})

    handlerShowUpdateActivate = () => this.setState({showUpdateActivate: true})
    handlerCloseUpdateActivate = () => this.setState({showUpdateActivate: false})

    handlerShowAlertDeleteUser = () => this.setState({showAlertDeleteUser: true})
    handlerCloseAlertDeleteUser = () => this.setState({showAlertDeleteUser: false})


    render() {
        return (
          <>
              <Container fluid>
                  <Alert show={this.state.show} variant="success" dismissible
                         onClose={() => this.setState({show: false})}
                         style={{margin: "2rem"}}>

                      <Alert.Heading>¡Contraseña!</Alert.Heading>
                      <p>
                          Se ha reiniciado la contraseña
                      </p>
                      <hr/>

                  </Alert>

                  <Alert show={this.state.showAlertUpdateMail} variant="success" dismissible
                         onClose={() => this.setState({showAlertUpdateMail: false})}
                         style={{margin: "2rem"}}>

                      <Alert.Heading>Email</Alert.Heading>
                      <p>
                          ¡Se ha actualizado el email!
                      </p>
                      <hr/>

                  </Alert>

                  <Alert show={this.state.showAlertUpdateRol} variant="success" dismissible
                         onClose={() => this.setState({showAlertUpdateRol: false})}
                         style={{margin: "2rem"}}>

                      <Alert.Heading>Rol</Alert.Heading>
                      <p>
                          ¡Se ha actualizado el rol!
                      </p>
                      <hr/>

                  </Alert>

                  <AlertIcintracom title={"Usuario"}
                                   text={"¡Se agrego exitosamente el usuario!"}
                                   show={this.state.showAlertAddUser}
                                   style={{margin: "4rem"}}
                                   close={this.handlerCloseAlertAdd}
                                   type={"success"}/>

                  <AlertIcintracom dismissible
                                   show={this.state.showUpdateActivate}
                                   variant="success"
                                   close={this.handlerCloseUpdateActivate}
                                   style={{margin: "4rem"}}
                                   title="Activar" text="¡Se ha actualizado la activación del usuario !"
                                   type={"success"}/>

                  <AlertIcintracom dismissible
                                   show={this.state.showAlertDeleteUser}
                                   variant="success"
                                   close={this.handlerCloseAlertDeleteUser}
                                   style={{margin: "4rem"}}
                                   title="Eliminar" text="¡Se ha eliminado el usuario !"
                                   type={"success"}/>

                  <Row className="justify-content-md-center">

                      <Col sm={10}>
                          <div style={styles.columnText}>
                              <h1 id="lbl_title_admin_users">Administración de Usuarios</h1>
                          </div>
                      </Col>

                  </Row>
                  {

                      this.state.loading === false ?
                        <Row className="justify-content-md-center" style={styles.topRow}>

                            <Col sm={2}>
                                <label>Total Usuarios : <b>{this.state.dataUsers.length}</b></label>
                            </Col>
                            <Col sm={2}>
                                <label>Total Clientes : <b>
                                    {this.state.dataUsers.reduce((sum, record) => {
                                        if (record.TIPOUSUARIO.toUpperCase() === 'CLIENTE') {
                                            return sum + 1
                                        }
                                        return sum
                                    }, 0)
                                    }
                                </b>
                                </label>
                            </Col>
                            <Col sm={2}>
                                <label>Total Internos : <b>
                                    {this.state.dataUsers.reduce((sum, record) => {
                                        if (record.TIPOUSUARIO.toUpperCase() === 'ICINTRACOM') {
                                            return sum + 1
                                        }
                                        return sum
                                    }, 0)
                                    }
                                </b>
                                </label>
                            </Col>
                            <Col sm={2}>
                                <label>Total Administradores : <b>
                                    {this.state.dataUsers.reduce((sum, record) => {
                                        if (record.TIPOUSUARIO === 'admin') {
                                            return sum + 1
                                        }
                                        return sum
                                    }, 0)
                                    }
                                </b>
                                </label>
                            </Col>
                            <Col sm={1}></Col>
                            <Col sm={2}>
                                <AddUser roles={this.state.dataRoles} dataUsers={this.state.dataUsers} getUsers={this.getUsers} handlerOpenAlertAdd={this.handlerOpenAlertAdd}/>
                            </Col>
                        </Row>
                        : <Row className="justify-content-md-center"> </Row>

                  }
                  <Row className="justify-content-md-center">

                      <Col sm={11}>
                          <DataTableExtensions
                            columns={this.columns}
                            data={this.state.dataUsers}
                            exportHeaders={false}
                            print={false}
                            export={false}
                            filterPlaceholder={"Buscar Usuario"}
                            fileName={"username"}>

                              <Datatable
                                columns={this.columns}
                                data={this.state.dataUsers}
                                noHeader
                                pagination
                                responsive
                                striped
                                highlightOnHover
                                dense
                                defaultSortField="name"
                                progressPending={this.state.loading}
                                progressComponent={<CustomLoader />}
                                noDataComponent={"No existen registros"}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Registros por página:',
                                    rangeSeparatorText: 'de'
                                }}
                              />
                          </DataTableExtensions>

                      </Col>

                  </Row>
              </Container>


              <Modal show={this.state.openModal} onHide={this.onCloseModal}>
                  <Modal.Header closeButton>
                      <Modal.Title>Eliminar Usuario</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="pure-form">
                      <div>
                          ¿Eliminar el usuario <b>{this.state.nameUser}</b>?
                      </div>
                      <p/>
                      <Modal.Footer>
                          <Button variant="primary" onClick={this.onDeleteUser}>Aceptar</Button>
                          <Button variant="secondary" onClick={this.onCloseModal}>Cancelar</Button>
                      </Modal.Footer>
                  </Modal.Body>

              </Modal>


          </>
        )
    }


}

export default AdminUsers;




